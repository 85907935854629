.research-request-page {
    background-color: var(--background-color);
    min-height: 100vh;
  }
  
  .form-section {
    padding: 2rem 0;
    margin: 0 auto;
    max-width: 90%;
  }
  
  .research-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .form-group {
    background: var(--primary-color);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: var(--shadow);
  }
  
  .form-group h3 {
    margin-bottom: 1.5rem;
    color: var(--text-color);
  }
  
  .name-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .research-form input,
  .research-form textarea {
    width: 100%;
    min-width: 500px; /* Increase the maximum width of input fields */
    padding: 1rem;
    margin-bottom: 1rem;
    border: 2px solid var(--black-color);
    border-radius: 4px;
    background: var(--background-color);
    color: var(--text-color);
    transition: primary-color 0.3s ease;
  }

  .research-form input:focus,
  .research-form textarea:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.1);
  }

  .research-form textarea {
    min-height: 120px;
    resize: vertical;
  }
  
  .research-form button {
    width: 100%;
    max-width: 800px; /* Increase the maximum width of the button */
    padding: 1rem;
    font-size: 1.1rem;
    margin-top: 1rem;
    transition: all 0.3s ease;
  }

  .research-form button:disabled {
    background-color: var(--primary-color);
    cursor: not-allowed;
  }

  .field-hint {
    font-size: 0.9rem;
    color: var(--text-color);
    opacity: 0.8;
    margin-bottom: 0.5rem;
    font-style: italic;
  }
  
  .success-message,
  .error-message {
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 4px;
    text-align: center;
  }
  
  .success-message {
    background-color: var(--success-color);
    color: var(--background-color);
  }
  
  .error-message {
    background-color: var(--error-color);
    color: var(--background-color);
  }

  @media (max-width: 768px) {
    .form-section {
      max-width: 100%;
      padding: 1rem;
    }

    .research-form input,
    .research-form textarea {
      min-width: unset;
    }

    .research-form button {
      max-width: unset;
    }

    .name-group {
      grid-template-columns: 1fr;
    }
  }