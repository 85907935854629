.footer {
  position: relative;
  bottom: -80px;
  width: 100%;
  background-color: var(--background-color); 
  text-align: center;
  padding-top: 1rem; /* Adjust padding as needed */
  padding-bottom: 0.5rem; /* Adjust padding as needed */
  z-index: 8; /* Ensure header is above other content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

}

.footer a p {
  color: var(--text-color);
  list-style-type: none;
}

.p {
  color: var(--text-color);
  list-style-type: none;
}
